import * as React from "react"
import _labels from "../data/language"
const { v4: uuidv4 } = require('uuid');

interface AppContextType {
    loading: boolean;
    locale: string;
    labels: any;
    isLoading: (loading: boolean) => void;
    handleLocale: (locale: string) => void;
    dataCap: any;
    handleDataCap: Function
    getDataCap: Function
    handleStorage: Function;
    stepperToken: string
}

const AppContext = React.createContext<AppContextType>(null!);


export default function AppProvider({ children }: { children: React.ReactNode }) {

    const _localeKey = '@jlr-locale'
    const _dataCapKey = '@jlr-data-cap'
    const _defaultLocale = 'fr'
    const stepperToken = uuidv4()

    const [locale, setLocale] = React.useState<string>(_defaultLocale)
    const [loading, isLoading] = React.useState<boolean>(false)
    const [dataCap, setDataCap] = React.useState<any>(undefined)

    React.useEffect(() => {

        isLoading(true)

        const locale: string = window.localStorage.getItem(_localeKey) ?? 'fr' as string

        if (locale) {
            setLocale(locale)
        }

    }, [])

    const handleLocale = (locale: string) => {
        setLocale(locale)
        window.localStorage.setItem(_localeKey, locale)
    }

    const handleDataCap = (data: string) => {
        //setDataCap(locale)
        window.localStorage.setItem(_dataCapKey, data)
    }

    const handleStorage = (key: string, value: string) => {
        window.localStorage.setItem(key, value)
    }

    const getDataCap = () => {
        const data = window.localStorage.getItem(_dataCapKey) as string

        return JSON.parse(data)
    }


    const labels = _labels[locale]

    const value = {
        loading,
        isLoading,
        locale,
        handleLocale,
        labels,
        dataCap,
        handleDataCap,
        getDataCap,
        handleStorage,
        stepperToken
    };

    return <AppContext.Provider value={value}>
        {children}
    </AppContext.Provider>;
}

export function useApp() {
    return React.useContext(AppContext);
}
