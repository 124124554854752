import {Outlet} from "react-router-dom"
import * as React from "react"

export default function GuestLayout({children}: any) {
    return (
        <div>
            <div style={{height: '100vh'}}>
                {children}
                <Outlet />
            </div>
        </div>
    );
}
